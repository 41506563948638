import React, { FC } from 'react';

import Card from '@/newcar/ui/detail/molecule/Card';
import reviewPointAvif from '@/newcar/ui/common/img/reviews/bnr-google-point.avif';
import reviewPointPng from '@/newcar/ui/common/img/reviews/bnr-google-point.png';
import * as styles from '@/newcar/ui/detail/organism/Reviews.module.styl';

const Reviews: FC = () => (
  <section id="reviews" className="container">
    <Card className={`${styles.reviewsContainer}`}>
      <div>
        <h2 className={`${styles.reviewsHeading} title is-3 has-text-centered is-margin-bottom-3`}>
          <span className={styles.reviewsSubheading}>
            定額カルモくんは
          </span>
          おかげさまで高評価
        </h2>
        <div className={styles.reviewsContent}>
          <picture className="is-block has-text-centered column">
            <source srcSet={reviewPointAvif} type="image/avif" />
            <source srcSet={reviewPointPng} />
            <img src={reviewPointPng} alt="Googleクチコミ高評価! 4.3獲得" loading="lazy" decoding="async" width="345" height="202" />
          </picture>
        </div>
      </div>

      <div>
        <h3 className={styles.reviewsUserCommentSpeeachBalloon}>喜びの声も続々！</h3>
        <ul className={`${styles.reviewsUserCommentList}`}>
          <li className={`${styles.reviewsUserCommentContainer} ${styles.isFirst}`}>
            <p className={styles.reviewsUserCommentItem}>
              <span className={styles.reviewsUserCommentText}>
                貯金がなくても
              </span>
              <span className={styles.reviewsUserCommentText}>
                乗りたいと思った新車に
              </span>
              <span className={styles.reviewsUserCommentText}>
                乗れるんだって
              </span>
              <span className={styles.reviewsUserCommentText}>
                正直、驚きましたね。
              </span>
              <span className={styles.reviewsUserCommentTextMin}>
                埼玉県 Mさん（20代）大工
              </span>
            </p>
          </li>
          <li className={`${styles.reviewsUserCommentContainer} ${styles.isSecond}`}>
            <p className={`${styles.reviewsUserCommentItem} ${styles.isSecond}`}>
              <span className={styles.reviewsUserCommentText}>
                子供の送り迎えも
              </span>
              <span className={styles.reviewsUserCommentText}>
                面倒だった家計の管理も
              </span>
              <span className={styles.reviewsUserCommentText}>
                グッとラクになりました！
              </span>
              <span className={styles.reviewsUserCommentTextMin}>
                東京都 Oさん（30代）主婦
              </span>
            </p>
          </li>
          <li className={`${styles.reviewsUserCommentContainer} ${styles.isThird}`}>
            <p className={styles.reviewsUserCommentItem}>
              <span className={styles.reviewsUserCommentText}>
                ずっと中古車だったから
              </span>
              <span className={styles.reviewsUserCommentText}>
                車検や税金を気にせず
              </span>
              <span className={styles.reviewsUserCommentText}>
                新車に乗れるのは
              </span>
              <span className={styles.reviewsUserCommentText}>
                かなり魅力的だった。
              </span>
              <span className={styles.reviewsUserCommentTextMin}>
                青森県 Uさん（40代）自営業
              </span>
            </p>
          </li>
          <li className={`${styles.reviewsUserCommentContainer} ${styles.isFourth}`}>
            <p className={`${styles.reviewsUserCommentItem} ${styles.isFourth}`}>
              <span className={styles.reviewsUserCommentText}>
                ローンの審査は落ちたけど
              </span>
              <span className={styles.reviewsUserCommentText}>
                カーリースの審査は
              </span>
              <span className={styles.reviewsUserCommentText}>
                無事通過で念願の新車に
              </span>
              <span className={styles.reviewsUserCommentText}>
                のれて満足です！
              </span>
              <span className={styles.reviewsUserCommentTextMin}>
                神奈川県 Kさん（40代）バス運転手
              </span>
            </p>
          </li>
        </ul>
        <div className="column is-full has-text-centered">
          <a
            href="/uservoice/"
            className={`button is-large is-fixed-width-button ${styles.reviewsMoreButton}`}
          >
            <span>「お客様の声」をもっとみる</span>
          </a>
        </div>
      </div>
    </Card>
  </section>
);

export default Reviews;
